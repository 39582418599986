/*.project-details-container {
    background-color: var(--grey);
    width: 68.375rem;
    height: 60.655rem;
    margin: 0 0.625rem 10.625rem 0;
    padding: 2.063rem 2.063rem 4.938rem 3.438rem;
    border-radius: 1.25rem;
} */

.project-details-header {
    display: flex;
    flex-flow: row;
    margin: 0.625rem;
    justify-content: space-between;
}

.status-and-client-section {
    display: flex;
    flex-flow: row;
    margin: 0 0.625rem 2.5rem;
    justify-content: space-between;
}

.status-section {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
}

.client-section {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
}

.info-scope-links-section {
    display: flex;
    flex-flow: row;
    margin: 0.625rem 0.625rem 2.5rem;
}

.info-scope-links-section a {
    color: inherit;
    text-decoration: none;
}

.details-tile-title {
    color: var(--general-color);
    margin: 1.375rem 1.125rem 3.188rem 0;
    font-size: 3.063rem;
    line-height: 1.04;
    letter-spacing: -0.306rem;
    text-align: left;
}

.color-rect-for-label {
    width: 5.813rem;
    height: max-content;
    padding: 0.438rem 0.438rem 0.375rem 0.5rem;
    border-radius: 0.375rem;
    background-color: var(--general-color);
    font-size: 1.563rem;
    line-height: 1;
    letter-spacing: -0.156rem;
    text-align: center;
    color: #302a29;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-details-header .color-rect-close {
    background-color: var(--general-color);
    width: 4.313rem;
    height: 4.563rem;
    margin: 0 0 3.188rem 0;
    padding: 0.125rem 1rem 0.625rem 1.063rem;
    border-radius: 0.938rem;
    cursor: pointer;
    border: none;
    line-height: 1.4;
}

.close-tile {
    width: 2.25rem;
    height: 3.813rem;
    font-size: 3.688rem;
    line-height: 1.03;
    margin: auto;
    color: var(--dark-grey);
}

.details-text {
    width: 87%;
    margin-left: 1rem;
    color: var(--light-grey);
    font-size: 1.625rem;
    letter-spacing: -0.09375rem;
    white-space: pre-wrap;
}
