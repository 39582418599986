.what {
    display: flex;
    justify-content:center;
    /* margin-left:-3.125rem; */
}

.what__left{
    display:flex;
    flex-flow:column;
}

.what__left .top{
    display:flex;
}

.what__left .top__left{
    display:flex;
    flex-flow:column;
}

.what__left .top__left .upper{
    display:flex;
    justify-content:flex-end;
}

.what__left .top__left .lower{
    display:flex;
}

.what__left .top__left .lower__left{
    display:flex;
    flex-flow:column;
    align-items:flex-end;
}

.what__left .top__left .lower__left .row1{
    display:flex;
}
.what__left .top__left .lower__right img{
    max-width: 15.938rem;
    height: 31.938rem;
    /* margin: 4px 0 23px 21px; */
    margin: 0.625rem;
    object-fit: fill;
    border-radius: 0.938rem;
}

.what__left .top__right{
    display:flex;
    flex-flow:column;
}

.what__left .top__right .upper{
    display:flex;
    justify-content:flex-end;
}

.what__left .top__right .upper__left img{
    max-width: 16.375rem;
    height: 20.938rem;
    /* margin: 20px 2px 17px 0px; */
    margin: 0.625rem;
    object-fit: fill;
    border-radius: 0.938rem;
}

.what__left .top__right .upper__right{
    display:flex;
    flex-flow:column;
    justify-content:flex-end;
}

.what__left .top__right .lower img{
    max-width: 25.063rem;
    height: 20.688rem;
    /* margin: 0px 25px 25px 21px; */
    margin: 0.625rem;
    object-fit: fill;
    border-radius: 0.938rem;
}
.what__left .bottom{
    display:flex;
    flex-flow:row;
    justify-content:flex-end;
}
.what__rightContent{
    height:100%;
    display:flex;
    flex-flow:column;
    /* gap:1.75rem; */
    justify-content:flex-start;
}

.what__rightContent .darkRectangle{
    margin-block:0;
}
.what__rightContentBottom{
    display:flex;
    justify-content:flex-end;
    /* padding-top:70px; */
}

.what__endRectangle{
    gap:3.75rem;
    height:100%;
    display:flex;
    flex-flow:column;
    justify-content:flex-end;
}

@media screen and (min-width:300px) and (max-width:480px){
    .web__hidden{
        display:block;
    }
    .mobile__hidden{
        display:none !important;
    }
    .what{
        flex-direction:column-reverse;
        margin-left:0px;
    }

    .what .what__mobileTop{
        display:flex;
        flex-flow:row;
        justify-content:space-between;
    }
    .what .what__mobileBottom{
        display:flex;
        flex-flow:row;
        justify-content:space-between;
    }
    .what__mobileTop .mobile__left{
        display:flex;
        flex-flow:row;
    }
    .what__mobileTop .mobile__right{
        display:flex;
        flex-flow:row;
    }
    .what__mobileBottom .mobile__left{
        display:flex;
        flex-flow:row;
    }
    .what__mobileBottom .mobile__right{
        display:flex;
        flex-flow:row;
    }
    .what__rightContent .mobile__left{
        display:flex;
        flex-flow:column;
        align-items:flex-start;
    }
    .what__rightContent .mobile__right{
        display:flex;
        flex-flow:column;
        align-items:flex-end;
    }
    .what .mobile__leftBottom{
        display:flex;
        flex-flow:row;
    }
    .what .what__rightContent{
        justify-content:space-between;
        flex-flow:row;
    }
    .what__blocksBottom.web__hidden{
        display:flex;
        flex-flow:row; 
    }
    .what .top .top__right{
        flex-flow:row;
    }
    .what .top .top__left img{
        max-width:100%;
        height:35.25rem;
        width:auto;
    }
    .what .top .top__right img{
        max-width:100%;
        height:35.25rem;
        width:auto;
    }
    .what .top .mobile__spacer.web__hidden{
        display:flex;
        flex-flow:column;
        justify-content:center;
        gap:1rem;
    }
    .what .what__left .top{
        justify-content:center;
    }
    .what .infoblock{
        margin-top:5rem;
    }
}

