.scroll-indicator {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
  }
  
  .arrow-down {
    display: block;
    width: 30px;
    height: 30px;
    border-left: 3px solid var(--general-color);
    border-bottom: 3px solid var(--general-color);
    transform:rotate(315deg);
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0) rotate(315deg);
    }
    40% {
      transform: translateY(-15px) rotate(315deg);
    }
    60% {
      transform: translateY(-15px) rotate(315deg);
    }
  }
  