.infoblock{
    display:flex;
    justify-content:center;
    width: 42.875rem;
}

.infoblock .text {
    display: flex;
    flex-flow: column;
}

.infoblock .title {
    display: flex;
    justify-content: flex-start;
    /* gap: 2.5rem; */
}
.infoblock .title__text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right:2.5rem;
}
.infoblock .title__text p {
    /* font-family: JdScarabeoLightRegular-EZVl; */
    /* font-family: 'jd_scarabeo_light'; */
    /* font-family: monospace; */
    font-family: OCRAExtended;
    font-size: 5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: -0.3rem;
    color: var(--general-color);
}

.infoblock .title__qmark{
    display:flex;
    justify-content:center;
    align-items:center;
    /* width: 4.375rem;
    height: 5.625rem; */
    padding:0.8rem 1rem;
    border-radius: 0.938rem;
    background-color: var(--general-color);
}

.infoblock .title__qmark p {
    font-family: OCRAExtended;
    /* font-family: monospace; */
    font-size: 3.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    color: var(--dark-grey);
}

.infoblock .text .para1 {
    display: flex;
    justify-content: flex-start;
}

.infoblock .text .para1 p {
    width: 100%;
    white-space: pre-line;
    margin-top: 7rem;
    font-family: ISOCPEUR;
    /* font-family: monospace; */
    font-size: 1.688rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: var(--general-color);
}

.infoblock .text .para2 {
    display: flex;
    justify-content: flex-start;
}

.infoblock .text .para2 p {
    display: flex;
    white-space: pre-line;
    width: 100%;
    margin-top: 3.125rem;
    font-family: ISOCPEUR;
    /* font-family: monospace; */
    font-size: 1.688rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: var(--light-grey);
}

@media screen and (min-width: 300px) and (max-width: 480px) {
    .infoblock {
        width: 60%;
    }
    .infoblock .title {
        justify-content: center;
        /* width:100%; */
    }
    .infoblock .title__text {
        justify-content: center;
    }
    .infoblock .title__text p{
        font-size:6rem;
        /* font-family: monospace; */
    }
    /* .infoblock .title__qmark{
        display:flex;
        justify-content:center;
        align-items:center;
        width: 4.375rem;
        height: 5.625rem;
        border-radius: 0.938rem;
        background-color: var(--general-color);
    } */
    .infoblock .title__qmark p{
        /* font-size:5rem; */
        /* font-family: monospace; */
    }
    .infoblock .text .para1 {
        justify-content: center;
        max-width: 100%;
    }
    .infoblock .text .para1 p{
        /* font-family: monospace; */
        white-space: normal;
        font-size: 3rem;
        -webkit-text-size-adjust: none;
        text-align: center;
        letter-spacing: 0.08rem;
        width: 100%;
    }
    .infoblock .text .para2 {
        justify-content: center;
        max-width: 100%;
    }
    .infoblock .text .para2 p{
        /* font-family: monospace; */
        white-space: normal;
        font-size: 3rem;
        text-align: center;
        letter-spacing: 0.08rem;
        width: 100%;
    }

}
