#m-search-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  font-size: 60px !important;
  background: var(--general-color) !important;
  width: 75px;
  height: 75px;
  border-radius: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 1s;
}

#m-popup {
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 1000;
  opacity: 0;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  visibility: hidden;
}

#m-popup.m-show {
  opacity: 1;
  visibility: visible;
}

#m-search-btn.m-active {
  transform: rotate(180deg);
}

.icon-custom {
  font-size: 40px !important; /* Adjust the size as needed */
}

