.intro {
    /* margin-top: 10%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1900px;
    /* margin-left: auto; */
    margin-inline: auto;
    margin-bottom:8rem;
    /* margin-bottom: 10%; */
    /* display:none; */
}

/* .intro__title {
    display: flex;
} */

/* .intro__titleLetter {
    display: flex;
    margin-right: 20px;
    background-color: blue;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 131.9px;
    padding: 0px 10px 5px;
    background-color: #60ffba;
    border-radius: 0.938rem;
} */

/* .intro__titleLetter > span {
    color: #222629;
    font-size: 1.813rem;
    font-weight: bold;
    font-family: 'OCRAExtended';
    font-family: monospace;
} */

/* .intro__click {
    cursor: pointer;
    display: flex;
    align-items: bottom;
} */

/* .intro__click > span {
    font-size: 1.25rem;
    padding-top: 5rem;
    font-weight: 500;
    font-family: 'OCRAExtended';
} */

.intro__subtitle {
    /* margin-top: 10px; */
  /* padding-left: 16rem; */
  /* margin-left: 31rem; */
    padding-bottom: 3.125rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.intro__subtitle .developers {
    font-weight: 500;
    color: var(--general-color);
    font-size: 4.5rem;
    /* font-size:50px; */
    font-family: 'OCRAExtended';
}
/* .intro__subtitle > span {
    font-weight: 500;
    color: var(--general-color);
    font-size: 50px;
    font-family: 'OCRAExtended';
} */

.intro__description {
  display:flex;
  justify-content:flex-start;
  align-items:center;
}

.intro__description > span {
    color: #a6a6a6;
    font-size: 2.088rem;
    /* font-size: 33px; */
    display: inline-block;
    padding-top: 0.625rem;
    font-family: 'OCRAExtended';
}

.intro .giveUs {
    /* height: 5.813rem; */
    padding-left: 1.875rem;
    /* padding-left: 30px; */
    font-family: OCRAExtended;
    font-size: 1.875rem;
    /* font-size: 30px; */
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: -0.094rem;
    text-align: left;
    color: #adadad;
}
.intro .join {
    /* height: 5.813rem; */
    padding-left: 1.875rem;
    /* padding-left: 30px; */
    font-family: OCRAExtended;
    font-size: 1.875rem;
    /* font-size: 30px; */
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: -0.094rem;
    text-align: left;
    color: #adadad;
    text-decoration:none;

}

@media screen and (min-width:300px) and (max-width: 640px) {
    /* .mobile__hidden{
        display:none;
    } */
    .intro__description > span {
        /* font-size: 1.75rem; */
        /* font-size:10px; */
        /* color:green; */
    }
    .intro .join,.intro .giveUs{
        padding-left:1rem;
        /* font-size:1.75rem; */
        /* font-size:10px; */

    }
    .intro__subtitle .developers{
        /* font-family:monospace; */
        /* width:10rem; */
        /* width:100%;
        font-size:100%; */
        /* font-size: 4rem; */
        font-size:auto;
    }
}

/* @media screen and (max-width: 640px) {
    .intro__description > span {
        font-size: 2.088rem;
    }
} */
