.show{
    margin-bottom:10rem;
}

.showcase {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 8.75rem;
    margin-bottom: 3.75rem;
    margin-left: 10rem;
    margin-right: 10rem;
}

.showcase-header {
    display: flex;
    width: 105rem;
    justify-content: space-between;
    margin-bottom: 4rem;
}

.showcase .buttons {
    margin-top: 1.25rem;
    display: flex;
}

.showcase .showcase-title h1 {
    width: 55.85rem;
    margin-top: 1.25rem;
    font-family: OCRAExtended;
    font-size: 5rem;
    font-weight: normal;
    text-align: center;
    line-height: 1.04;
    letter-spacing: -0.5rem;
    color: var(--general-color);
    align-self: flex-start;
}

.showcase .navigation-arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.375rem;
    height: 5.625rem;
    padding: 1rem;
    margin-right: 1rem;
    border-radius: 0.938rem;
    background-color: var(--general-color);
    outline: none;
    cursor: pointer;
}

.showcase .navigation-arrows .arrow-icon {
    font-family: OCRAExtended;
    font-size: 4rem;
    font-weight: normal;
    font-style: normal;
    align-self: center;
    color: var(--dark-grey);
}

.showcase-slider {
    width: max-content;
}

.showcase .projects-slider-tile {
    /* width: 21.875rem;
    height: 25rem; */
    /* width:50rem; */
    margin-right:4rem;
    /* width: 50rem; */
    display:flex;
    /* gap:4rem; */

}

.projects-slider-tile .col {
    display: flex;
    flex-flow: row;
    justify-content:flex-start;
    background-color: var(--grey);
    padding: 3.25rem 4.75rem 4.5rem 2.5rem;
    width: 50rem;
    height: 30rem;
    border-radius: 0.938rem;
    margin: 0.313rem;
}

.projects-slider-tile .col:hover {
    border-bottom: solid var(--general-color) 2px;
}

.projects-slider-tile .col-text {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    /* width: 17.563rem; */
    margin-right:2rem;
    /* padding-right: 5rem; */
}

.col-text .content {
    width: 5rem;
    height: 1.875rem;
    border-radius: 0.375rem;
    background-color: var(--general-color);
}

.projects-slider-tile .col-text .content p {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: OCRAExtended;
    font-size: 1.125rem;
    -webkit-text-size-adjust: none;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.113rem;
    color: #302a29;
}

.projects-slider-tile .col-text h4 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    font-family: OCRAExtended;
    font-size: 2.5rem;
    -webkit-text-size-adjust: none;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: -0.25rem;
    text-align: left;
    color: var(--general-color);
    width: 20rem;
}

.projects-slider-tile .col-text h5 {
    font-family: OCRAExtended;
    font-size: 1.375rem;
    -webkit-text-size-adjust: none;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: -0.138rem;
    text-align: left;
    color: var(--light-grey);
    white-space: pre-line;
    /* width: 18rem; */
}

.projects-slider-tile .col-image {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* width: 100rem;  */
}

.projects-slider-tile .col-image img {
    width: 11.438rem;
    height: 24.274rem;
    border-radius: 0.938rem;
    margin-bottom: -1rem;
}
.showcase .showcase_wrapper{
    width:43.75rem !important;
}

.showcase .showcase_overall_wrapper{
    width:calc(50rem * 10) !important;
}
/* @media screen and (max-width: 1920px) and (min-width: 1780px) {
    .projects-slider-tile {
        margin-bottom: 10rem;
    }
    .projects-slider-tile .col {
        padding: 3.25rem 2rem 3.25rem 2rem;
        width: 34rem;
        height: 31rem !important;
        margin: 0.2rem;
    }
    .projects-slider-tile .col-text h4 {
        font-size: 2.5rem;
        text-align: left;
    }
    .projects-slider-tile .col-text h5 {
        font-size: 1.5rem;
        text-align: left;
    }
    .projects-slider-tile .col-image img {
        width: 11.5rem;
        height: 25rem;
    }
}

@media screen and (max-width: 1780px) and (min-width: 1520px) {
    .projects-slider-tile {
        margin-bottom: 10rem;
    }
    .projects-slider-tile .col {
        padding: 3.25rem 0.75rem 4.5rem 2.5rem;
        width: 33rem;
        height: 30rem !important;
        margin: 0.2rem;
    }
    .projects-slider-tile .col-text h4 {
        font-size: 2.5rem;
        text-align: left;
    }
    .projects-slider-tile .col-text h5 {
        font-size: 1.45rem;
        text-align: left;
    }
    .projects-slider-tile .col-image img {
        width: 11.438rem;
        height: 24.274rem;
    }
}

@media screen and (max-width: 1520px) and (min-width: 1280px) {
    .projects-slider-tile {
        margin-bottom: 9rem;
    }
    .projects-slider-tile .col {
        padding: 3.2rem 0.7rem 3.25rem 2rem;
        width: 32rem;
        height: 29rem !important;
        margin: 0.1rem;
    }
    .projects-slider-tile .col-text h4 {
        font-size: 2.5rem;
        text-align: left;
    }
    .projects-slider-tile .col-text h5 {
        font-size: 1.35rem;
        text-align: left;
    }
    .projects-slider-tile .col-image img {
        width: 11rem;
        height: 22rem;
    }
}

@media screen and (max-width: 1280px) and (min-width: 980px) {
    .projects-slider-tile {
        margin-bottom: 8rem;
    }
    .projects-slider-tile .col {
        padding: 3.25rem 2rem 3.25rem 2rem;
        width: 31rem;
        height: 28rem !important;
        margin: 0.2rem;
    }
    .projects-slider-tile .col-text h4 {
        font-size: 2.5rem;
        text-align: left;
    }
    .projects-slider-tile .col-text h5 {
        font-size: 1.33rem;
        text-align: left;
    }
    .projects-slider-tile .col-image img {
        width: 10rem;
        height: 21rem;
    }
}

@media screen and (max-width: 980px) and (min-width: 720px) {
    .projects-slider-tile {
        margin-bottom: 8rem;
    }
    .projects-slider-tile .col {
        padding: 3.25rem 2rem 3.25rem 2rem;
        width: 31rem;
        height: 28.2rem !important;
        margin: 0.1rem;
    }
    .projects-slider-tile .col-text h4 {
        font-size: 2.5rem;
        text-align: left;
    }
    .projects-slider-tile .col-text h5 {
        font-size: 1.33rem;
        text-align: left;
    }
    .projects-slider-tile .col-image img {
        width: 10rem;
        height: 20.5rem;
    }
}

@media screen and (max-width: 720px) and (min-width: 480px) {
    .projects-slider-tile {
        margin-bottom: 8rem;
    }
    .projects-slider-tile .col {
        padding: 3.25rem 1.5rem 3.25rem 1.7rem;
        width: 32.5rem;
        height: 28rem !important;
        margin: 0.1rem;
    }
    .projects-slider-tile .col-text h4 {
        font-size: 2.3rem;
        text-align: left;
    }
    .projects-slider-tile .col-text h5 {
        font-size: 1.3rem;
        text-align: left;
    }
    .projects-slider-tile .col-image img {
        width: 10rem;
        height: 20rem;
    }
} */
/* 
@media screen and (max-width: 480px) {
    .projects-slider-tile {
        margin-bottom: 12rem;
    }
    .projects-slider-tile .col {
        padding: 3.25rem 1.5rem 3.25rem 2rem;
        width: 32.5rem;
        height: 28rem !important;
        margin: 0.1rem;
    }
    .projects-slider-tile .col-text h4 {
        font-size: 2.3rem;
        text-align: left;
    }
    .projects-slider-tile .col-text h5 {
        font-size: 1.3rem;
        text-align: left;
    }
    .projects-slider-tile .col-image img {
        width: 10rem;
        height: 20rem;
    }
} */
