.Grid {
    width: 100%;
    /* z-index: 1; */
    /* opacity:0%; */
    /* animation:fade 5s 5s ease forwards; */
    /* animation:fade 1s ease forwards; */
}
@keyframes fade{
    0%{
        opacity:0%;
    }
    100%{
        opacity:100%;
    }
}
.grid-item {
    background-color: var(--grey);
    color: var(--general-color);
    border-radius: 0.938rem;
    /* border-radius: 15px; */
    display: flex;
    justify-content: center;
}
.link {
    /* color: var(--general-color); */
    background-color: var(--grey);
    border-radius: 0.938rem;
    /* border-radius: 15px; */
    text-align: center;
    cursor: pointer;
    border:var(--general-color) solid 0.08rem;
    /* border-color:var(--general-color);
    border-width: 0.5rem; */
}

.link:hover{
    box-shadow: 0 0 0.8rem var(--general-color); 
}
.grid-item-invisible {
    background-color: var(--dark-grey);
    color: var(--general-color);
    border-radius: 0.938rem;
    /* border-radius: 15px; */
    display: flex;
    justify-content: center;
    z-index: -1;
}
.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--grey);
    border-radius: 0.938rem;
}
.grid-item-color {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: var(--general-color);
    /* background-color: var(--grey); */
    border-radius: 0.938rem;
}
.grid-item-color > span {
    color: #222629;
    /* color:var(--general-color); */
    font-size: 9.375rem;
    font-weight: 900;
    font-family: 'OCRAExtended';
    /* font-family: "JD Scarabeo Light"; */
}
.grid-item-clickme {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
}

.grid-item-clickme > span {
    padding-left: 0.625rem;
    padding-bottom: 0.625rem;
    font-family: OCRAExtended;
    font-size: 1.825rem;
    -webkit-text-size-adjust: none;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: -0.094rem;
    text-align: left;
    color: var(--dark-grey);
}
.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
}
.react-grid-item {
    transition: all 500ms ease;
    transition-property: left, top;
}
.react-grid-item.cssTransforms {
    transition-property: transform;
}
.react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
}
.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
}

.react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.link {
    display: flex;
    align-items: center;
}

@media screen and (min-width:300px) and (max-width: 640px) {
    .grid-item-color > span {
        font-family: 'OCRAExtended';
    }
    .grid-item-clickme > span {
        width:auto;
        height:auto;
        font-family: OCRAExtended;
    }
}





/* .react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
} */
